<template>
  <div class="DepartmentInfo">
    <OrganizationMenu />
    <div class="wrap">
      <el-descriptions title="部门/班组信息" border :column="2">
        <template slot="extra">
          <el-button
            type="primary"
            v-if="isRead"
            @click="toggleEditMode"
            size="small"
            plain
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            type="primary"
            v-if="!isRead"
            size="small"
            plain
            icon="el-icon-check"
            @click="saveEditData"
            >保存</el-button
          >
          <el-button
            type="danger"
            v-if="!isRead && action != 'add'"
            @click="isRead = true"
            size="small"
            plain
            icon="el-icon-close"
            >取消</el-button
          >
          <el-button
            type="danger"
            v-if="isRead || action =='add'"
            @click="$router.push({
               name: 'Department',
               query: {
                projectId: projectId,
                deptId: deptId,
              },
            })"
            size="small"
            plain
            icon="el-icon-back"
            >返回</el-button
          >
        </template>
        <el-descriptions-item label="部门/班组名称" labelClassName="my-label" contentClassName="my-content">
          <span v-if="isRead">{{ readData.labourGroupName }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.labourGroupName"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="负责人姓名" labelClassName="my-label" contentClassName="my-content">
          <span v-if="isRead">{{ readData.leader }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.leader"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="负责人证件号">
          <span v-if="isRead">{{ readData.leaderCard }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.leaderCard"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="负责人电话">
          <span v-if="isRead">{{ readData.phone }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.phone"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="进场时间">
          <span v-if="isRead">{{ readData.approachTime }}</span>
          <el-date-picker
            v-if="!isRead"
            v-model="editData.approachTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item label="离场时间">
          <span v-if="isRead">{{ readData.outTime }}</span>
          <el-date-picker
            v-if="!isRead"
            v-model="editData.outTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-descriptions-item>
      </el-descriptions>
      
      <div class="wrap-blank"></div>

      <el-descriptions title="进场/离场附件" border :column="1">
        <el-descriptions-item label="进场附件" labelClassName="my-label1" contentClassName="my-content1">
          <el-image
            v-if="isRead && readData.approachAccessory"
            style="width: 288px; height: 162px;"
            :src="'api' + readData.approachAccessory"
            fit="scale-down">
          </el-image>
          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadApproachAccessory'
            >
            <img v-if="blob1" :src="blob1">
            <i v-if="!blob1" class="el-icon-upload"></i>
            <div v-if="!blob1" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob1" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-descriptions-item>
        <el-descriptions-item label="离场附件" labelClassName="my-label1" contentClassName="my-content1">
          <el-image
            v-if="isRead && readData.outAccessory"
            style="width: 288px; height: 162px;"
            :src="'api' + readData.outAccessory"
            fit="scale-down">
          </el-image>
          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadOutAccessory'
            >
            <img v-if="blob2" :src="blob2">
            <i v-if="!blob2" class="el-icon-upload"></i>
            <div v-if="!blob2" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob2" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-descriptions-item>
      </el-descriptions>      
    </div>
  </div>
</template>

<style lang="less" scope>
.DepartmentInfo {
  .my-label {
    width: 20%;
  }
  .my-content {
    width: 30%;
  }
  .my-label1 {
    width: 20%;
  }
  .my-content1 {
    width: 80%;
  }
  .el-date-editor {
    width: 100% !important;
  }
  .upload-demo {
    width: 288px;
    height: 162px;
  }
  .el-upload-dragger {
    width: 288px;
    height: 162px;
  }
}
</style>


<script>
import OrganizationMenu from "@/components/OrganizationMenu.vue";

export default {
  name: "DepartmentInfo",
  components: {
    OrganizationMenu,
  },
  data() {
    return {
      loading: null,
      isRead: true,

      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },

      fileFormData: null, // 将要上传的formdata数据
      blob1: null,
      blob2: null,
// {
//     "id": 30,
//     "projectId": "13",
//     "groupNo": "563139",
//     "deptId": "74073",
//     "labourGroupName": "津联劳务",
//     "leader": "郭刚",
//     "leaderCard": "210621197106190013",
//     "phone": null,
//     "approachTime": null,
//     "outTime": null,
//     "approachAccessory": null,
//     "outAccessory": null,
//     "type": 1,
//     "createTime": "2021-06-22 16:29:01",
//     "createBy": "admin",
//     "updateBy": null,
//     "updateTime": null,
//     "delFlag": "0"
// }
      readData: {
        labourGroupName: null, // 部门/班组名称
        leader: null, // 负责人姓名
        leaderCard: null, // 负责人证件号
        phone: null, // 负责人电话
        approachTime: null, // 进场时间
        outTime: null, // 离场时间
        approachAccessory: null, // 进场附件
        outAccessory: null, // 离场附件
      },
      editData: {
        labourGroupName: null, // 部门/班组名称
        leader: null, // 负责人姓名
        leaderCard: null, // 负责人证件号
        phone: null, // 负责人电话
        approachTime: null, // 进场时间
        outTime: null, // 离场时间
        approachAccessory: null, // 进场附件
        outAccessory: null, // 离场附件
      },
    };
  },
  computed: {
    action: function () {
      return this.$route.query.action;
    },
    projectId: function () {
      return this.$route.query.projectId;
    },
    deptId: function () {
      return this.$route.query.deptId;
    },
    departmentId: function () {
      return this.$route.query.departmentId;
    },
  },
  mounted() {
    this.getDepartmentData() // 根据 id 查询部门信息
  },
  watch: {},
  methods: {
    // 进入编辑模式
    toggleEditMode: function () {
      this.isRead = false
      this.editData = JSON.parse(JSON.stringify(this.readData))
      this.blob1 = null
      this.blob2 = null
    },

    // 根据 id 查询部门信息
    getDepartmentData: function () {
      var that = this;
      if (that.action == 'add') {
        that.isRead = false
        return;
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: that.departmentId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/getLabourGroup",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getDepartmentData =>", res);
          that.readData = JSON.parse(JSON.stringify(res.data))
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 上传进场确认附件
    uploadApproachAccessory: function () {

    },
    // 上传离场确认附件
    uploadOutAccessory: function () {

    },
    // 保存表单数据
    saveEditData: function () {
      var that = this
      if (that.action == 'add') {
        that.onAddDepartment() // 创建单位
      } else if (that.action == 'read') {
        that.onEditDepartment() // 编辑单位
      }
    },
    // 创建单位
    onAddDepartment: function () {
      var that = this
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.projectId,
        deptId: that.deptId,
        labourGroupName: that.editData.labourGroupName, // 部门/班组名称
        leader: that.editData.leader, // 负责人姓名
        leaderCard: that.editData.leaderCard, // 负责人证件号
        phone: that.editData.phone, // 负责人电话
        approachTime: that.editData.approachTime, // 进场时间
        outTime: that.editData.outTime, // 离场时间
        approachAccessory: that.editData.approachAccessory, // 进场附件
        outAccessory: that.editData.outAccessory, // 离场附件
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/insertLabourgroup",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("onAddDepartment =>", res);
          if (res.code == 0) {
            that.$notify({
              title: "操作成功",
              message: "已成功添加部门/班组",
              type: "success",
            });
            that.$router.push({
               name: 'Department',
               query: {
                projectId: that.projectId,
                deptId: that.deptId,
              },
            })
          } else {
            that.$notify({
              title: "警告",
              message: "添加部门/班组失败",
              type: "warning",
            });
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    }, 
     // 编辑单位
    onEditDepartment: function () {
      var that = this
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: that.departmentId,
        labourGroupName: that.editData.labourGroupName, // 部门/班组名称
        leader: that.editData.leader, // 负责人姓名
        leaderCard: that.editData.leaderCard, // 负责人证件号
        phone: that.editData.phone, // 负责人电话
        approachTime: that.editData.approachTime, // 进场时间
        outTime: that.editData.outTime, // 离场时间
        approachAccessory: that.editData.approachAccessory, // 进场附件
        outAccessory: that.editData.outAccessory, // 离场附件
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/editLabourgroup",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("onEditDepartment =>", res);
          if (res.code == 0) {
            that.$notify({
              title: "操作成功",
              message: "已成功编辑部门/班组",
              type: "success",
            });
            that.$router.push({
               name: 'Department',
               query: {
                projectId: that.projectId,
                deptId: that.deptId,
              },
            })
          } else {
            that.$notify({
              title: "警告",
              message: "编辑部门/班组失败",
              type: "warning",
            });
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
  },
};
</script>